import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  Spin,
  Tooltip,
  Breadcrumb,
  Button,
  Select,
  Input,
  InputNumber,
  Divider,
} from 'antd';
import _, { divide, map, multiply, sum, toNumber } from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import './Orders.css';
import {
  getItemPriceWithTaxations,
  getItemsCustomizations,
  getTotalByTaxation,
} from '../CommonLogics/CommonMethods';

const OrderDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { restaurant, isOrderLoading, allOrders, allOrderProcess, Order } =
    useSelector((state) => {
      const { restaurant } = state.restaurant;
      const { allOrders, Order, isOrderLoading } = state.order;
      const { allOrderProcess } = state.orderProcess;
      return {
        restaurant,
        isOrderLoading,
        allOrders,
        allOrderProcess,
        Order,
      };
    });
  const { orgId, orderId } = useParams();
  const organizationCurrencySymbol =
    restaurant?.organizations_detail?.default_currency_symbol;

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };

  const handleCloseOrganization = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const handleCloseOrder = () => {
    dispatch({
      type: 'GET_ORDER_BY_ID_SUCCESS',
      payload: '',
    });
    navigate(`/restaurants/${orgId}/orders`);
  };

  return (
    <Spin spinning={isOrderLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseOrganization()}
                  className='breadcrumRestaurant'
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumRestaurant'
                  onClick={() => handleCloseOrder()}
                >
                  Orders
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  {Order?.order_number}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={24}>
              <Typography className='heading'>{Order?.order_number}</Typography>
            </Col>
          </Row>
          <Row className='orderDetailContainer'>
            <Col span={24} className='orderDetailheadings'>
              <Typography>Token Number {Order?.token_number}</Typography>
            </Col>
            <Divider className='orderDetailDivider' />
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row gutter={[0, 10]}>
                    <Col span={24} className='orderDetailheadings'>
                      <Typography>Order Details</Typography>
                    </Col>
                    <Col span={24}>
                      <Row justify='center'>
                        <Col span={4} className='orderDetailSubheadings'>
                          <Row>
                            <Col span={24}>
                              <Typography className='orderDetailHead'>
                                Order Number
                              </Typography>
                            </Col>
                            <Col span={24}>
                              <Typography className='orderDetailText'>
                                {Order?.order_number}
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={4} className='orderDetailSubheadings'>
                          <Row>
                            <Col span={24}>
                              <Typography className='orderDetailHead'>
                                Order Type
                              </Typography>
                            </Col>
                            <Col span={24}>
                              <Typography className='orderDetailText'>
                                {Order?.order_type}
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={4} className='orderDetailSubheadings'>
                          <Row>
                            <Col span={24}>
                              <Typography className='orderDetailHead'>
                                Order Time
                              </Typography>
                            </Col>
                            <Col span={24}>
                              <Typography className='orderDetailText'>
                                {moment(Order?.order_created).format(
                                  'HH:mm:ss'
                                )}
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={4} className='orderDetailSubheadings'>
                          <Row>
                            <Col span={24}>
                              <Typography className='orderDetailHead'>
                                Order Date
                              </Typography>
                            </Col>
                            <Col span={24}>
                              <Typography className='orderDetailText'>
                                {moment(Order?.order_created).format(
                                  'DD/MM/YYYY'
                                )}
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={4} className='orderDetailSubheadings'>
                          <Row>
                            <Col span={24}>
                              <Typography className='orderDetailHead'>
                                Order Status
                              </Typography>
                            </Col>
                            <Col span={24}>
                              <Typography className='orderDetailText'>
                                {Order?.order_status}
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Divider className='orderDetailDivider' />
                <Col span={24}>
                  <Row gutter={[0, 10]}>
                    <Col span={24} className='orderDetailheadings'>
                      <Typography>Customer Details</Typography>
                    </Col>

                    <Col span={24}>
                      <Row justify='center'>
                        <Col span={4} className='orderDetailSubheadings'>
                          <Row justify='center'>
                            <Col span={24}>
                              <Typography className='orderDetailHead'>
                                Customer Name
                              </Typography>
                            </Col>
                            <Col span={24}>
                              <Typography className='orderDetailText'>
                                {Order?.user?.first_name}{' '}
                                {Order?.user?.last_name}
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={4} className='orderDetailSubheadings'>
                          <Row>
                            <Col span={24}>
                              <Typography className='orderDetailHead'>
                                Customer Number
                              </Typography>
                            </Col>
                            <Col span={24}>
                              <Typography className='orderDetailText'>
                                {Order?.user?.country_code} {Order?.user?.phone}
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Divider className='orderDetailDivider' />
                <Col span={24}>
                  <Row gutter={[0, 10]}>
                    <Col span={24} className='orderDetailheadings'>
                      <Typography>Item Details</Typography>
                    </Col>

                    <Col span={24}>
                      <Row justify='center'>
                        <Col span={23}>
                          <Row>
                            <Col span={14}>
                              <Typography className='orderDetailHead'>
                                Items
                              </Typography>
                            </Col>
                            <Col span={2}>
                              <Typography className='orderDetailHead'>
                                Price
                              </Typography>
                            </Col>
                            <Col span={4}>
                              <Typography className='orderDetailHead'>
                                Qty
                              </Typography>
                            </Col>
                            <Col span={4} style={{ textAlign: 'end' }}>
                              <Typography className='orderDetailHead'>
                                Sub Total
                              </Typography>
                            </Col>

                            {Order?.order_items?.map((val, i) => {
                              const ItemCustomizations = getItemsCustomizations(
                                Order?.order_items,
                                val,
                                null,
                                'default'
                              );
                              const ItemCustomizationsText =
                                getItemsCustomizations(
                                  Order?.order_items,
                                  val,
                                  (item) =>
                                    item?.item_quantity +
                                    ' x ' +
                                    item?.item?.name +
                                    `${
                                      item?.item?.quantity &&
                                      item?.item?.quantity_unit
                                        ? `-${item.item.quantity}${item.item.quantity_unit}`
                                        : ''
                                    } ${organizationCurrencySymbol}${
                                      item?.item?.display_price
                                    }`
                                );

                              const displayItemPrice = Order?.inclusive_gst
                                ? getItemPriceWithTaxations(val, null, Order)
                                : toNumber(val?.display_item_price);

                              const customizedItemsPrice = sum(
                                map(ItemCustomizations, (value) => {
                                  return Order?.inclusive_gst
                                    ? toNumber(
                                        getItemPriceWithTaxations(
                                          value,
                                          'order-customization',
                                          Order
                                        )
                                      )
                                    : multiply(
                                        value?.item_quantity,
                                        toNumber(value?.item?.display_price)
                                      );
                                })
                              );

                              const ItemTotal = sum([
                                displayItemPrice,
                                ItemCustomizations?.length > 0
                                  ? customizedItemsPrice
                                  : 0,
                              ]);

                              const SubTotal = multiply(
                                ItemTotal,
                                val?.item_quantity
                              ).toFixed(2);
                              return (
                                <>
                                  <Col span={14} key={i}>
                                    <Typography className='orderDetailText'>
                                      {val?.item?.name}
                                    </Typography>
                                  </Col>
                                  <Col span={2}>
                                    <Typography className='orderDetailText'>
                                      {organizationCurrencySymbol}
                                      {val?.display_item_price}
                                    </Typography>
                                  </Col>
                                  <Col span={4}>
                                    <Typography className='orderDetailText'>
                                      {val?.item_quantity}
                                    </Typography>
                                  </Col>
                                  <Col span={4} style={{ textAlign: 'end' }}>
                                    <Typography className='orderDetailText'>
                                      {organizationCurrencySymbol}
                                      {SubTotal}
                                    </Typography>
                                  </Col>
                                </>
                              );
                            })}
                          </Row>
                          <Divider className='orderDetailItemDivider' />
                          <Row>
                            <Col span={20}>
                              <Typography className='orderDetailItemDetailText'>
                                Sub Total
                              </Typography>
                            </Col>
                            <Col span={4} style={{ textAlign: 'end' }}>
                              <Typography className='orderDetailItemDetailText'>
                                {organizationCurrencySymbol}
                                {Order?.display_sub_total}
                              </Typography>
                            </Col>
                            <Col span={24}>
                              <Row>
                                {Order?.taxations?.length > 0
                                  ? Order?.taxations?.map((taxation, i) => {
                                      return (
                                        <Col span={24}>
                                          <Row>
                                            <Col span={20}>
                                              <Typography className='orderDetailItemDetailText'>
                                                {taxation?.taxation_name}(
                                                {taxation?.display_tax_rate}%)
                                              </Typography>
                                            </Col>
                                            <Col
                                              span={4}
                                              style={{ textAlign: 'end' }}
                                            >
                                              <Typography className='orderDetailItemDetailText'>
                                                {organizationCurrencySymbol}
                                                {getTotalByTaxation(
                                                  Order?.order_items,
                                                  taxation,
                                                  restaurant,
                                                  'order'
                                                )?.toFixed(2)}
                                              </Typography>
                                            </Col>
                                          </Row>
                                        </Col>
                                      );
                                    })
                                  : ''}
                              </Row>
                            </Col>
                            <Divider className='orderDetailItemDivider' />
                            <Col span={20}>
                              <Typography className='orderDetailText'>
                                Total
                              </Typography>
                            </Col>
                            <Col span={4} style={{ textAlign: 'end' }}>
                              <Typography className='orderDetailText'>
                                {organizationCurrencySymbol}
                                {Order?.display_total}
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Typography className='heading'>Payment Information</Typography>
            </Col>
          </Row>
          <Row className='orderDetailContainer'>
            <Col span={24}>
              <Row>
                {Order?.order_payments?.map((payment, i) => {
                  return (
                    <Col span={24} key={i}>
                      <Row gutter={[0, 10]}>
                        <Col span={24}>
                          <Row>
                            <Col span={6} className='orderDetailSubheadings'>
                              <Row>
                                <Col span={24}>
                                  <Typography className='orderDetailHead'>
                                    Payment Time
                                  </Typography>
                                </Col>
                                <Col span={24}>
                                  <Typography className='orderDetailText'>
                                    {moment(
                                      payment?.payment_mode?.created_at
                                    ).format('DD/MM/YYYY hh:mm A')}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={6} className='orderDetailSubheadings'>
                              <Row>
                                <Col span={24}>
                                  <Typography className='orderDetailHead'>
                                    Mode
                                  </Typography>
                                </Col>
                                <Col span={24}>
                                  <Typography className='orderDetailText'>
                                    {payment?.payment_mode?.mode}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={6} className='orderDetailSubheadings'>
                              <Row>
                                <Col span={24}>
                                  <Typography className='orderDetailHead'>
                                    Type
                                  </Typography>
                                </Col>
                                <Col span={24}>
                                  <Typography className='orderDetailText'>
                                    {payment?.payment_type}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={6} className='orderDetailSubheadings'>
                              <Row>
                                <Col span={24}>
                                  <Typography className='orderDetailHead'>
                                    Amount
                                  </Typography>
                                </Col>
                                <Col span={24}>
                                  <Typography className='orderDetailText'>
                                    {divide(
                                      payment?.payment_type === 'Credit'
                                        ? payment?.pay_amount
                                        : payment?.payment_type === 'Debit'
                                        ? payment?.refund_amount
                                        : 0,
                                      100
                                    )?.toFixed(2)}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {i !== Order?.order_payments?.length - 1 && (
                            <Divider className='orderDetailDivider' />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default OrderDetails;
