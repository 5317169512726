import API from '../Utils/API';

export const getOrders = (params) => (dispatch) => {
  dispatch({ type: 'GET_ORDERS_REQUEST' });
  API.get(`api/orders${params}`)
    .then((response) => {
      dispatch({ type: 'GET_ORDERS_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_ORDERS_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const getOrderById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_ORDER_BY_ID_REQUEST' });
  API.get(`api/orders/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_ORDER_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_ORDER_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
