import API from '../Utils/API';
import setOrg from '../Utils/setOrg';
// import constants from "../Utils/constants";
// import { customToken } from "../Screens/CommonLogics/CommonMethods";

// const token = customToken();

export const getRestaurant = (url) => (dispatch) => {
  dispatch({ type: 'GET_RESTAURANT_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_RESTAURANT_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_RESTAURANT_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const getRestaurantDetails = (data) => (dispatch) => {
  dispatch({ type: 'GET_RESTAURANT_DETAILS_REQUEST' });
  API.get(`api/organizations_details`)
    .then((response) => {
      const tokenData = response.data.id;
      localStorage.getItem('organization_id', tokenData);
      setOrg(tokenData);
      dispatch({
        type: 'GET_RESTAURANT_DETAILS_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_RESTAURANT_DETAILS_FAILURE',
        payload: error.response?.data,
      });
    });
};
export const updateOrganizationFssaiDetailAction =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_RESTAURANT_DETAILS_REQUEST' });
    let formData = new FormData();
    formData.append('organizations_detail[expiry_date]', data.expiry_date);
    formData.append('organizations_detail[fssai_registration_number]',data.fssai_registration_number);
    formData.append('organizations_detail[is_fssai]', data.is_fssai);
    formData.append('organizations_detail[gst_number]', data.gst_number);
    formData.append('organizations_detail[is_gst]', data.is_gst);
    data.fssai_document && formData.append('organizations_detail[fssai_document]',data.fssai_document);
    data.gst_document && formData.append('organizations_detail[gst_document]', data.gst_document);

    API.put(`api/organizations_details/${data?.id}`, formData)
      .then((response) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_DETAILS_SUCCESS',
          payload: response.data,
        });
        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_DETAILS_FAILURE',
          payload: error.response.data,
        });
        failureCallback && failureCallback();
      });
  };

export const updateRestaurantDetails =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: 'UPDATE_RESTAURANT_DETAILS_REQUEST' });
    let formData = new FormData();
    formData.append('organizations_detail[address_line_1]',data.address_line_1);
    formData.append('organizations_detail[address_line_2]',data.address_line_2);
    formData.append('organizations_detail[city]', data.city);
    formData.append('organizations_detail[zip_code]', data.zip_code);
    formData.append('organizations_detail[email]', data.email);
    formData.append('organizations_detail[fax]', data.fax);
    formData.append('organizations_detail[phone]', data.phone);
    API.put(`api/organizations_details/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_DETAILS_SUCCESS',
          payload: response.data,
        });
        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_DETAILS_FAILURE',
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const updateRestaurantOperationTimeAction =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_RESTAURANT_DETAILS_REQUEST' });
    API.put(`api/organizations_details/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_DETAILS_SUCCESS',
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_DETAILS_FAILURE',
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
export const getRestaurantDetailsById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_RESTAURANT_DETAILS_BY_ID_REQUEST' });
  API.get(`api/organizations_details/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_RESTAURANT_DETAILS_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_RESTAURANT_DETAILS_BY_ID_FAILURE',
        payload: error.response.data,
      });
    });
};
export const getRestaurantById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_RESTAURANT_BY_ID_REQUEST' });
  API.get(`api/organizations/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_RESTAURANT_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_RESTAURANT_BY_ID_FAILURE',
        payload: error.response.data,
      });
    });
};
export const deleteRestaurant =
  (Id, successDeleteCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_RESTAURANT_REQUEST' });
    API.delete(`api/organizations/${Id}`)
      .then((response) => {
        dispatch({
          type: 'DELETE_RESTAURANT_SUCCESS',
          payload: response.data,
        });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_RESTAURANT_FAILURE',
          payload: error?.response?.data,
        });
        failureCallback && failureCallback();
      });
  };

export const createRestaurantAction =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: 'CREATE_RESTAURANT_REQUEST' });

    let formData = new FormData();
    formData.append('organization[name]', data.name);
    formData.append('organization[legal_entity_name]', data.legal_entity_name);
    formData.append('organization[is_cafe]', data.is_cafe);
    formData.append('organization[is_cashier]', data.is_cashier);
    formData.append('organization[is_cloud_kitchen]', data.is_cloud_kitchen);
    formData.append('organization[is_dashboard]', data.is_dashboard);
    formData.append('organization[is_dine_in]', data.is_dine_in);
    formData.append('organization[is_delivery]', data.is_delivery);
    formData.append('organization[is_food_court]', data.is_food_court);
    formData.append('organization[is_inventory]', data.is_inventory);
    formData.append('organization[online_store]', data.online_store);
    formData.append('organization[is_test]', data.is_test);
    formData.append('organization[is_mobile_restaurant]',data.is_mobile_restaurant);
    formData.append('organization[is_orders]', data.is_orders);
    formData.append('organization[is_qsr]', data.is_qsr);
    formData.append('organization[is_settings]', data.is_settings);
    formData.append('organization[is_take_away]', data.is_take_away);
    formData.append('organization[is_workstation]', data.is_workstation);
    formData.append('country_id', data.country_id);
    formData.append('state_id', data.state_id);
    formData.append('time_zone', data.time_zone);
    formData.append('phone', data.phone);
    formData.append('email', data.email);
    formData.append('fax', data.fax);
    data.org_logo && formData.append('organization[org_logo]', data.org_logo);
    API.post('api/organizations', formData)
      .then((response) => {
        dispatch({
          type: 'CREATE_RESTAURANT_SUCCESS',
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_RESTAURANT_FAILURE',
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const updateRestaurantAction =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: 'UPDATE_RESTAURANT_REQUEST' });
    let formData = new FormData();
    formData.append('organization[name]', data.name);
    formData.append('country_id', data.country_id);
    formData.append('state_id', data.state_id);
    formData.append('time_zone', data.time_zone);
    data.org_logo && formData.append('organization[org_logo]', data.org_logo);
    formData.append('organization[legal_entity_name]', data.legal_entity_name);
    formData.append('organization[is_dine_in]', data.is_dine_in);
    formData.append('organization[is_delivery]', data.is_delivery);
    formData.append('organization[is_take_away]', data.is_take_away);
    API.put(`api/organizations/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_SUCCESS',
          payload: response.data,
        });
        successCallback && successCallback(response?.data);
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_FAILURE',
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
export const updatePublishRestaurantAction =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_RESTAURANT_REQUEST' });
    let formData = new FormData();
    formData.append('organization[published]', data.published);
    API.put(`api/organizations/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_SUCCESS',
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_FAILURE',
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const updateBrandingLogo =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_BRANDING_LOGO_REQUEST' });
    let formData = new FormData();
    data.online_org_logo &&
      formData.append('online_org_logo', data.online_org_logo);
    data.app_clip_logo && formData.append('app_clip_logo', data.app_clip_logo);
    data.instant_app_clip_logo &&
      formData.append('instant_app_clip_logo', data.instant_app_clip_logo);
    data.pdf_logo && formData.append('pdf_logo', data.pdf_logo);
    API.put(`api/organization_branding_logo`, formData)
      .then((response) => {
        dispatch({
          type: 'UPDATE_BRANDING_LOGO_SUCCESS',
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_BRANDING_LOGO_FAILURE',
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
  export const updatefeturesRestaurantAction =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_RESTAURANT_REQUEST' });
    let formData = new FormData();
    formData.append('organization[is_cafe]', data.is_cafe);
    formData.append('organization[is_cashier]', data.is_cashier);
    formData.append('organization[is_cloud_kitchen]', data.is_cloud_kitchen);
    formData.append('organization[is_dashboard]', data.is_dashboard);
    formData.append('organization[is_dine_in]', data.is_dine_in);
    formData.append('organization[is_delivery]', data.is_delivery);
    formData.append('organization[is_food_court]', data.is_food_court);
    formData.append('organization[is_inventory]', data.is_inventory);
    formData.append('organization[online_store]', data.online_store);
    formData.append('organization[is_test]', data.is_test);
    formData.append('organization[is_mobile_restaurant]',data.is_mobile_restaurant);
    formData.append('organization[is_orders]', data.is_orders);
    formData.append('organization[is_qsr]', data.is_qsr);
    formData.append('organization[is_settings]', data.is_settings);
    formData.append('organization[is_take_away]', data.is_take_away);
    formData.append('organization[is_workstation]', data.is_workstation);
    formData.append('organization[is_queue]',data.is_queue);
    formData.append('organization[is_customer]', data.is_customer);
    formData.append('organization[is_kds]', data.is_kds);
    formData.append('organization[is_menu]', data.is_menu);
    formData.append('organization[is_surveys]', data.is_surveys);
    formData.append('organization[is_captain]', data.is_captain);
    API.put(`api/organizations/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_SUCCESS',
          payload: response.data,
        });
        successCallback && successCallback(response?.data);
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_FAILURE',
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
  export const updateRestaurantRazorPay =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_RESTAURANT_DETAILS_REQUEST' });
    let formData = new FormData();
    formData.append('organizations_detail[razor_pay_api_key]', data.razor_pay_api_key);
    formData.append('organizations_detail[razor_pay_key_id]', data.razor_pay_key_id);
    API.put(`api/organizations_details/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_DETAILS_SUCCESS',
          payload: response.data,
        });
        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_DETAILS_FAILURE',
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
  export const updateRestaurantStripeKey =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_RESTAURANT_DETAILS_REQUEST' });
    let formData = new FormData();
    formData.append('organizations_detail[stripe_publishable_key]', data.stripe_publishable_key);
    formData.append('organizations_detail[stripe_secret_key]', data.stripe_secret_key);
    API.put(`api/organizations_details/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_DETAILS_SUCCESS',
          payload: response.data,
        });
        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_RESTAURANT_DETAILS_FAILURE',
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
