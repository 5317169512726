import React, { useEffect, useState } from 'react';
import './Restaurant.css';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  message,
  Card,
  Input
} from 'antd';
import {
  updateRestaurantRazorPay,
  getRestaurantDetails,
} from '../../Actions/RestaurantAction';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';

const RazorPay = (props) => {
  const {
    editId,
    setEditId,
    setIsDefaultImageVisible,
    setShowUploadImage,
    setTabkey
  } = props;
  const dispatch = useDispatch();
  const [newFiles, setNewFiles] = useState();
  const IsEmpty = _.isEmpty(newFiles) ? true : false;
  const [preloadedRestaurantValue, setPreloadedRestaurantValue] = useState('');
  const {
    restaurantdeatils,
    allRestaurant,
    isCreateRestaurantLoading,
    isUpdateRestaurantLoading,
  } = useSelector((state) => {
    const {
      restaurantdeatils,
      allRestaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
    } = state.restaurant;
    return {
        restaurantdeatils,
      allRestaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
    };
  });
  const { organizations } = allRestaurant;

  const the_restaurant = _.filter(organizations, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
        razor_pay_api_key: restaurantdeatils?.razor_pay_api_key,
        razor_pay_key_id: restaurantdeatils?.razor_pay_key_id,
    },
  });
  const handleCancel = () => {
    reset(preloadedRestaurantValue);
    setEditId('');
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    clearErrors();
  };
  useEffect(() => {
    if (editId) {
      dispatch(getRestaurantDetails());
    }
  }, [dispatch]);
  
  const handleEditRestaurantMethod = (data) => {
    data.id = restaurantdeatils?.id;
    dispatch(
        updateRestaurantRazorPay(data, UpdateCallBack, faliureUpdate, IsEmpty)
    );
  };

  const UpdateCallBack = () => {
    setTabkey('1');
    setTimeout(() => {
      message.success('Your Restaurant Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #f6f6f6 inset',
  };
  const isLoading = isCreateRestaurantLoading || isUpdateRestaurantLoading;
  return (
    <Spin spinning={isLoading}>
      <Card
        style={{
          backgroundColor: '#f6f6f6',
          border: 'rgb(229, 229, 229,1)',
        }}
      >
        <form
           onSubmit={handleSubmit(handleEditRestaurantMethod)}
        >
          <Spin spinning={isUpdateRestaurantLoading}>
            <>
            <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                Razor pay API key &nbsp;
              </Typography>
              <Controller
                as={<Input style={inputStyles}></Input>}
                name='razor_pay_api_key'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                Razor pay key ID &nbsp;
              </Typography>
              <Controller
                as={<Input style={inputStyles}></Input>}
                name='razor_pay_key_id'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          <Row gutter={[22, 22]} className='button' justify='center'>
                <Col span={24}></Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Button
                      className='cancelButton'
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Button className='editDesignationButton' htmlType='submit'>
                    Update
                  </Button>
                </Col>
                <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
              </Row>
            </>
          </Spin>
        </form>
      </Card>
    </Spin>
  );
};

export default RazorPay;

