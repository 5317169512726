import API from '../Utils/API';

export const getEmployee = (params) => (dispatch) => {
  dispatch({ type: 'GET_EMPLOYEE_REQUEST' });
  API.get(`api/organization_employee_users${params}`)
    .then((response) => {
      dispatch({ type: 'GET_EMPLOYEE_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_EMPLOYEE_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createEmployee =
  (data, successCreateEmployee, failureCreateEmployee) => (dispatch) => {
    dispatch({ type: 'CREATE_EMPLOYEE_REQUEST' });
    let formData = new FormData();
    formData.append('user[first_name]', data.first_name);
    formData.append('user[last_name]', data.last_name);
    formData.append('user[phone]', data.phone);
    formData.append('user[country_code]', data.country_code);
    formData.append('user_type', JSON.stringify(data.user_type));
    data.avatar && formData.append('user[avatar]', data.avatar);
    API.post('api/create_employee_user', formData)
      .then((response) => {
        dispatch({
          type: 'CREATE_EMPLOYEE_SUCCESS',
          payload: response.data,
        });
        successCreateEmployee && successCreateEmployee();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_EMPLOYEE_FAILURE',
          payload: error?.response?.data,
        });
        failureCreateEmployee && failureCreateEmployee(error.response.data);
      });
  };
export const deleteEmployee =
  (EmployeeId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_EMPLOYEE_REQUEST' });
    API.delete(`api/organization_users/${EmployeeId}`)
      .then((response) => {
        dispatch({
          type: 'DELETE_EMPLOYEE_SUCCESS',
          payload: response.data,
        });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_EMPLOYEE_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateEmployeeAddType =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_EMPLOYEE_ADD_TYPE_REQUEST' });
    API.put(`api/add_type/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_EMPLOYEE_ADD_TYPE_SUCCESS',
          payload: response?.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_EMPLOYEE_ADD_TYPE_FAILURE',
          payload: error?.response?.data,
        });
      });
  };
export const updateEmployeeRemoveType =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_EMPLOYEE_REMOVE_TYPE_REQUEST' });
    API.put(`api/remove_type/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_EMPLOYEE_REMOVE_TYPE_SUCCESS',
          payload: response?.data,
        });
        successUpdateCallBack && successUpdateCallBack(response?.data);
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_EMPLOYEE_REMOVE_TYPE_FAILURE',
          payload: error?.response?.data,
        });
      });
  };
