import constants from '../Utils/constants';
const initialState = {
  isOrderLoading: false,
  allOrders: [],
  Order: {},
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ORDERS_REQUEST:
      return {
        ...state,
        isOrderLoading: true,
      };
    case constants.GET_ORDERS_SUCCESS:
      return {
        ...state,
        isOrderLoading: false,
        allOrders: action.payload,
      };
    case constants.GET_ORDERS_FAILURE:
      return {
        ...state,
        isOrderLoading: false,
      };
    case constants.GET_ORDER_BY_ID_REQUEST:
      return {
        ...state,
        isOrderLoading: true,
      };
    case constants.GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        isOrderLoading: false,
        Order: action?.payload,
      };
    case constants.GET_ORDER_BY_ID_FAILURE:
      return {
        ...state,
        isOrderLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
