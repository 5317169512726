import API from '../Utils/API';

export const getCountry = (params) => (dispatch) => {
  dispatch({ type: 'GET_COUNTRY_REQUEST' });
  API.get(`api/countries${params}`)
    .then((response) => {
      dispatch({ type: 'GET_COUNTRY_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_COUNTRY_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const getAllCountry = (url) => (dispatch) => {
  dispatch({ type: 'GET_COUNTRIES_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_COUNTRIES_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_COUNTRIES_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createCountry =
  (data, successCreateCountry, failureCreateCountry) => (dispatch) => {
    dispatch({ type: 'CREATE_COUNTRY_REQUEST' });
    API.post('api/countries', data)
      .then((response) => {
        dispatch({ type: 'CREATE_COUNTRY_SUCCESS', payload: response.data });
        successCreateCountry && successCreateCountry();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_COUNTRY_FAILURE',
          payload: error.response.data,
        });
        failureCreateCountry && failureCreateCountry(error.response.data);
      });
  };
export const deleteCountry =
  (countryId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_COUNTRY_REQUEST' });
    API.delete(`api/countries/${countryId}`)
      .then((response) => {
        dispatch({ type: 'DELETE_COUNTRY_SUCCESS', payload: response.data });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_COUNTRY_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateCountry =
  (data, successUpdateCallBack, failureUpdate) => (dispatch) => {
    dispatch({ type: 'UPDATE_COUNTRY_REQUEST' });
    API.put(`api/countries/${data.id}`, data)
      .then((response) => {
        dispatch({ type: 'UPDATE_COUNTRY_SUCCESS', payload: response.data });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_COUNTRY_FAILURE',
          payload: error.response.data,
        });
        failureUpdate && failureUpdate(error.response.data);
      });
  };
export const getCountryById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_COUNTRY_BY_ID_REQUEST' });
  API.get(`api/countries/${Id}`)
    .then((response) => {
      dispatch({ type: 'GET_COUNTRY_BY_ID_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_COUNTRY_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
